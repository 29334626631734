.underline-text {
    text-decoration: underline;
  }

  .rounded-lg > .bg-white {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }

  /* .shadow-no-border {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  } */

  .logoheight{
    height:50px;
  }
  

  .text-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
  }
  
  .text-animation {
    position: relative;
    animation: climbAndDescend 6s linear infinite;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  
  @keyframes climbAndDescend {
    0% {
      transform: translate(0, 0) rotate(0deg);
    }
    25% {
      transform: translate(50px, -100px) rotate(-20deg);
    }
    50% {
      transform: translate(100px, 0) rotate(0deg);
    }
    75% {
      transform: translate(150px, -100px) rotate(20deg);
    }
    100% {
      transform: translate(200px, 0) rotate(0deg);
    }
  }
  